import React from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import Breadcumb from '../../Breadcumb/Main'
import BG from '../../../assets/images/background/cta-two-bg-1-1.jpg'
import aboutCompanyImg from'../../../assets/images/resources/about-1-1.jpg'
import serviceImg3 from '../../../assets/images/services/service-1-3.jpg'
import serviceImg2 from '../../../assets/images/services/service-1-2.jpg'
import serviceImg1 from '../../../assets/images/services/service-1-1.jpg'

const Service1 = () => {
  const logoOptions = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    nav: true,
    navText: ["<span class=\"fa fa-angle-left\"></span>", "<span class=\"fa fa-angle-right\"></span>"],
    dots: false,
    margin: 30,
    items: 2,
    smartSpeed: 700,
    responsive: {
      0: {
        margin: 30,
        items: 2
      },
      375: {
        margin: 30,
        items: 2
      },
      575: {
        margin: 30,
        items: 3
      },
      767: {
        margin: 50,
        items: 4
      },
      991: {
        margin: 40,
        items: 5
      },
      1199: {
        margin: 80,
        items: 5
      }
    }
  }
  const serviceOptions = {
    loop: false,
    nav: true,
    dots: false,
    items: 1,
    navText: ["<span class=\"fa fa-angle-left\"></span>", "<span class=\"fa fa-angle-right\"></span>"],
    margin: 0,
    responsive: {
      0: {
        items: 1,
        margin: 0
      },
      768: {
        items: 1,
        margin: 30
      },
      1200: {
        items: 2,
        margin: 30
      },
      1700: {
        items: 3,
        margin: 30
      }
    }
  }
  return (
    <>
      <Breadcumb Title="Services" Breadcumb="SERVICES" />
        <section className="section-padding--bottom section-padding--top service-one">
          <div className="container-fluid">

            <OwlCarousel className="thm-owl__carousel service-one__carousel" {...serviceOptions}>
              <div className="item">
                <div className="service-card-one gray-bg">
                  <div className="service-card-one__image">
                    <img src={serviceImg2} alt="" />
                    <div className="service-card-one__logo">I</div>
                  </div>
                  <div className="service-card-one__content">
                    <h3 className="service-card-one__title">
                      <Link to="cyber-security">Infrastructure of the Company's Projects</Link>
                    </h3>
                    <p className="service-card-one__text">We leverage advanced, scalable, and secure infrastructure solutions,
                      comprising cutting-edge hardware and software, for all our projects to ensure efficient operations, seamless
                      execution, and optimal results.
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="service-card-one gray-bg">
                  <div className="service-card-one__image">
                    <img src={serviceImg1} alt="" />
                    <div className="service-card-one__logo">S</div>
                  </div>
                  <div className="service-card-one__content">
                    <h3 className="service-card-one__title">
                      <Link to="cyber-security">Software Engineering</Link>
                    </h3>
                    <p className="service-card-one__text">Software development is a collaborative effort in which we use the
                      latest methodologies and technologies to design, develop, test and maintain customized software solutions,
                      increasing efficiency to meet our clients' business needs.
                    </p>

                  </div>
                </div>
              </div>
              <div className="item">
                <div className="service-card-one gray-bg">
                  <div className="service-card-one__image">
                    <img src={serviceImg3} alt="" />
                    <div className="service-card-one__logo">T</div>
                  </div>
                  <div className="service-card-one__content">
                    <h3 className="service-card-one__title">
                      <Link to="cyber-security">Information and Technology</Link>
                    </h3>
                    <p className="service-card-one__text">We use information and technology to build strong and flexible digital
                      services, helping businesses to grow, become more efficient, and create value. Businesses can count on our
                      expertise to transform their operations.
                    </p>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </section>

        <section className="section-padding--bottom">
          <div className="container">
            <div className="section-title text-center">
              <p className="section-title__text">Company Benefits</p>
              <h2 className="section-title__title">
                We Provide Best IT Solutions For Business <br />22 Years We Provide
                Solutions
              </h2>
            </div>
            <div className="row gutter-y-30">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="service-card-two gray-bg">
                  <h3 className="service-card-two__title">
                    <Link to="/service-01">Quality Service</Link>
                  </h3>

                  <p className="service-card-two__text">
                    We are dedicated to providing top-notch services that our clients
                    can always rely on and be satisfied with.
                  </p>
                  <div className="service-card-two__icon">
                    <i className="icon-smart-tv"></i>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="service-card-two gray-bg">
                  <h3 className="service-card-two__title">
                    <Link to="/team">Expert Team</Link>
                  </h3>
                  <p className="service-card-two__text">
                    Our team is filled with skilled professionals who use their expertise
                    to provide the best solutions for our clients.
                  </p>
                  <div className="service-card-two__icon">
                    <i className="icon-link"></i>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="service-card-two gray-bg">
                  <h3 className="service-card-two__title">
                    <Link to="/about">Solutions</Link>
                  </h3>
                  <p className="service-card-two__text">
                    We are committed to providing forward-thinking solutions
                    that are key to business growth and success.
                  </p>
                  <div className="service-card-two__icon">
                    <i className="icon-cctv"></i>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="service-card-two gray-bg">
                  <h3 className="service-card-two__title">
                    <Link to="/contact">Excellent Support</Link>
                  </h3>
                  <p className="service-card-two__text">
                    We offer exceptional support, always ready to help and solve any
                    issues our clients may face.
                  </p>
                  <div className="service-card-two__icon">
                    <i className="icon-technical-support"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="black-bg section-padding-lg--top section-padding-lg--bottom cta-two">
          <div className="cta-two__bg jarallax" data-jarallax data-speed="0.2" data-imgPosition="50% 0%"
               style={{ backgroundImage: `url(${BG})` }}></div>
          <div className="container">
            <div className="cta-two__inner">
              <h3 className="cta-two__title">Better IT Solutions And Services
                At Your <span>Fingertips</span></h3>
              <Link to="/contact" className="thm-btn cta-two__btn">
                <span>Learn More</span>
              </Link>
            </div>
          </div>
        </section>

      <section className="section-padding--top section-padding--bottom about-one">
        <div className="container">
          <div className="row gutter-y-60">
            <div className="col-lg-6">
              <div className="about-one__image">
                <img src={aboutCompanyImg} className="wow fadeInUp"
                  data-wow-duration="1500ms" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-one__content">
                <div className="section-title ">
                  <p className="section-title__text">About Company</p>
                  <h2 className="section-title__title">Experts of Software and Technology</h2>
                </div>
                <div className="about-one__text">
                  At SELIUK LTD, we don't just provide services - we build partnerships. We collaborate with you to
                  understand your challenges and provide innovative solutions. With our unparalleled technical expertise
                  and customer-centric approach, we're ready to help you succeed.
                </div>
                <ul className="about-one__list">
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Refreshing to get such a personal touch.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Enabling businesses to improve operations.
                  </li>
                  <li>
                    <i className="fa fa-check-circle"></i>
                    Contact us today to learn more about how we can help your business thrive.
                  </li>
                </ul>
                <Link to="/about" className="thm-btn about-one__btn"><span>Learn More</span></Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cta-one">
        <div className="container">
          <div className="cta-one__inner text-center wow fadeInUp" data-wow-duration="1500ms">
            <div className="cta-one__circle"></div>
            <div className="section-title ">
              <p className="section-title__text">Need Any Technology Solution</p>
              <h2 className="section-title__title section-title__title--light">Let's collaborate and work together on a project</h2>
            </div>
            <Link to="/contact" className="thm-btn thm-btn--light cta-one__btn">
              <span>Start Now</span>
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default Service1