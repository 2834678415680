import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../assets/images/Seliuk-LTD-logo.svg'
import WOW from 'wowjs'

const Navbar = () => {
    const location = useLocation()
    const path = location.pathname
    const [sticky, setSticky] = useState(false)
    const [search, setSearch] = useState(false)

    const [menu, setmenu] = useState({})

    const activeMenu = () => {
        if(path === "/" || path === "/home-02" || path === "/home-03" ){
            setmenu({home:true})
        }else if(path === "/about" || path === "/team"){
            setmenu({pages:true})
        }else if(path === "/service-01" || path === "/service-02" || path === "qa-testing" || path === "/it-management" || path === "cyber-security" || path === "/it-consultant" || path === "/infrastructure-plan"){
            setmenu({services:true})
        }else if(path === "/projects" || path === "/project-details"){
            setmenu({project:true})
        }else if(path === "/blog" || path === "/blog-details"){
            setmenu({blog:true})
        }else if(path === "/contact"){
            setmenu({contact:true})
        }else{
            setmenu({home:true})
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    useEffect(()=>{
        window.scroll(0, 0)
        new WOW.WOW({
          live: false
        }).init();
        activeMenu()
    }, [path])
    
    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }

    return (
        <>
            <div className="topbar">
                <div className="container-fluid">
                    <ul className="topbar__info">
                        <li>
                            <i className="fa fa-envelope"></i>
                            <Link to="mailto:company@seliuk.ltd">company@seliuk.ltd</Link>
                        </li>
                        <li>
                            <i className="fa fa-map-marker"></i>
                            Nicodemou Mylona, Themis Court 3, Block A, Office 312, 8046, Paphos, Cyprus
                        </li>
                    </ul>
                </div>
            </div>
            <nav className={`main-menu sticky-header ${sticky && "sticky-header--cloned sticky-fixed"}`}>
                <div className="container-fluid">
                    <div className="main-menu__logo">
                        <Link to="/">
                            <img src={Logo} width="40" height="50" alt="Seliuk LTD logotype" />
                        </Link>
                        <div className="main-menu__cta__tex">
                            <b className="sticky-bottom">SELIUK LTD</b>
                        </div>
                    </div>

                    <ul className="main-menu__list">
                        <li className={`menu-item-has-children ${menu.home && "current"}`}>
                            <Link to="/">Home</Link>
                        </li>
                        <li className={`menu-item-has-children ${menu.pages && "current"}`}>
                            <Link to="/about">About Us</Link>
                        </li>
                        <li className={`menu-item-has-children ${menu.services && "current"}`}>
                            <Link to="/service-01">Services</Link>
                            {/*<ul>*/}
                            {/*    <li><Link to="/service-01">Services 01</Link></li>*/}
                            {/*    <li><Link to="/service-02">Services 02</Link></li>*/}
                            {/*    <li><Link to="/cyber-security">Cyber Security</Link></li>*/}
                            {/*    <li><Link to="/it-management">IT Management</Link></li>*/}
                            {/*    <li><Link to="/qa-testing">QA & Testing</Link></li>*/}
                            {/*    <li><Link to="/infrastructure-plan">Infrastructure Plan</Link></li>*/}
                            {/*    <li><Link to="/it-consultant">IT Consultant</Link></li>*/}
                            {/*</ul>*/}
                        </li>
                        {/*<li className={`menu-item-has-children ${menu.project && "current"}`}>*/}
                        {/*    <Link to="/projects">Projects</Link>*/}
                        {/*    <ul>*/}
                        {/*        <li><Link to="/projects">Projects</Link></li>*/}
                        {/*        <li><Link to="/project-details">Projects Details</Link></li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                        {/*<li className={`menu-item-has-children ${menu.blog && "current"}`}>*/}
                        {/*    <Link to="/blog">Blog</Link>*/}
                        {/*    <ul>*/}
                        {/*        <li><Link to="/blog">Blog</Link></li>*/}
                        {/*        <li><Link to="/blog-details">Blog Details</Link></li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}
                        <li className={`menu-item-has-children ${menu.contact && "current"}`}>
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>

                    <div className="main-menu__right">
                        <Link to="#" className="mobile-nav__toggler">
                            <span></span>
                            <span></span>
                            <span></span>
                        </Link>
                        <Link to="#" onClick={()=>setSearch(true)} className="search-toggler">
                            <i className="icon-magnifying-glass"></i>
                        </Link>
                        <Link to="tel:+35797754125" className="main-menu__cta">
                            <i className="fa fa-phone-alt"></i>
                            <span className="main-menu__cta__text">
                                <b>+357 97 754125</b>
                            </span>
                        </Link>
                    </div>

                </div>
            </nav>
            <div className={`search-popup ${search && "active"}`}>
                <div className="search-popup__overlay search-toggler" onClick={()=>setSearch(false)}></div>
                <div className="search-popup__content">
                    <form action="#">
                        <label htmlFor="search" className="sr-only">search here</label>
                        <input type="text" id="search" placeholder="Search Here..." />
                        <button type="submit" aria-label="search submit" className="thm-btn">
                            <span>
                                <i className="icon-magnifying-glass"></i>
                            </span>
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Navbar