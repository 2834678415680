import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../assets/images/Seliuk-LTD-logo.svg'

const Footer = () => {
    const location = useLocation();
    const [footerPadding, setfooterPadding] = useState(false);
    useEffect(() => {
      if(location.pathname === "/" || location.pathname === "/service-01"){
        setfooterPadding(true)
      }else{
        setfooterPadding(false)
      }
    }, [location])
    
    return (
        <>
            <div className={`footer-main section-padding--bottom ${footerPadding ? "footer-main--top-padding" : "section-padding--top"}`}>
                <div className="container">
                    <div className="row gutter-y-30">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--about">
                                <Link to="/" className="footer-widget__logo">
                                    <img src={Logo} width="40" height="40" alt="Seliuk LTD logotype" />
                                </Link>
                                <div className="main-menu__cta__text">
                                    <b className="sticky-bottom">SELIUK LTD</b>
                                </div>
                                <p className="footer-widget__text">Coding Your Vision: From Idea to Implementation.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12"></div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--links">
                                <h3 className="footer-widget__title">Services</h3>
                                <ul className="footer-widget__links">
                                    <li className="pe-none">
                                        <Link to="/full-stack-development">Full Stack Development</Link>
                                    </li>
                                    <li className="pe-none">
                                        <Link to="/devops-sre-services">DevOps/SRE Services</Link>
                                    </li>
                                    <li className="pe-none">
                                        <Link to="/frontend-development">Frontend Development</Link>
                                    </li>
                                    <li className="pe-none">
                                        <Link to="/backend-development">Python Development</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--contact">
                                <h3 className="footer-widget__title">Contact</h3>
                                <ul className="footer-widget__contact">
                                    <li>
                                        <i className="fa fa-phone"></i>
                                        <Link to="tel:+35797754125">+357 97 754125</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-envelope"></i>
                                        <Link to="mailto:company@seliuk.ltd">company@seliuk.ltd</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-map-marker-alt"></i>
                                        Nicodemou Mylona, Themis Court 3, Block A, Office 312, 8046  <br /> Paphos. Cyprus
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <p>&copy; All rights reserved. <span className="dynamic-year"></span></p>
                </div>
            </div>
        </>
    )
}

export default Footer