import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import BGImg from '../../assets/images/background/cta-two-bg-1.jpg'
import BGImg1 from '../../assets/images/background/home-bg1.jpg'
import BGImg2 from '../../assets/images/background/home-bg2.jpg'
import BGImg3 from '../../assets/images/background/home-bg3.jpg'
import BGImg4 from '../../assets/images/background/home-bg4.jpg'
import BGImg5 from '../../assets/images/shapes/testi-bg-1-1.png'
import BGImg6 from '../../assets/images/shapes/team-home-bg-1-1.jpg'
import BGImg7 from '../../assets/images/shapes/about-three-s-1.png'
import BGImg8 from '../../assets/images/shapes/service-four-bg-1-1.png'
import BGImg9 from '../../assets/images/shapes/funfact-one-bg.png'
import img1 from '../../assets/images/resources/about-five-1.jpg'
import img2 from '../../assets/images/resources/_about-five-2.jpg'
import whyChoose from '../../assets/images/resources/about-three-1-1.png'
import authorImg from '../../assets/images/resources/ceo.jpg'
import gravity4 from '../../assets/images/partners/gravity4.png'
import redLotus from '../../assets/images/partners/red-lotus.png'
import cams from '../../assets/images/partners/cams.png'
import hCapcha from '../../assets/images/partners/h-capcha.png'
import gartner from '../../assets/images/partners/gartner.png'
import vonq from '../../assets/images/partners/vonq.png'
import crossover from '../../assets/images/partners/crossover.png'
import davinci from '../../assets/images/partners/davinci.png'
import businessApps from '../../assets/images/partners/business-apps.png'
import schoolLoop from '../../assets/images/partners/school-loop.png'
import AnimatedNumber from "animated-number-react"
import { Parallax } from 'react-parallax'

const Home = () => {

    const [ytShow, setytShow] = useState(false)
    const options = {
        loop: false,
        margin: 0,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: false,
        items: 1,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 0,
                items: 1
            },
            576: {
                margin: 30,
                items: 2
            },
            768: {
                margin: 30,
                items: 3
            },
            992: {
                margin: 30,
                items: 4
            },
            1200: {
                margin: 30,
                items: 5
            }
        },
    };
    const sliderOptions = {
        loop: true,
        items: 1,
        navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
        margin: 0,
        dots: true,
        nav: true,
        animateOut: "slideOutDown",
        animateIn: "fadeIn",
        smartSpeed: 1000,
        autoplay: true,
        autoplayTimeout: 7000,
        autoplayHoverPause: false
    }
    const projectOptions = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        nav: true,
        navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
        dots: false,
        margin: 0,
        items: 1,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 0,
                items: 1
            },
            768: {
                margin: 30,
                items: 2
            },
            992: {
                margin: 30,
                items: 3
            }
        }
    }
    const logoOptions = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        nav: true,
        navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
        dots: false,
        margin: 30,
        items: 2,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 30,
                items: 2
            },
            375: {
                margin: 30,
                items: 2
            },
            575: {
                margin: 30,
                items: 3
            },
            767: {
                margin: 50,
                items: 4
            },
            991: {
                margin: 40,
                items: 5
            },
            1199: {
                margin: 80,
                items: 5
            }
        }
    }
    return (
        <>
            <div className="slider-one">
                <OwlCarousel className="slider-one__carousel owl-theme thm-owl__carousel" {...sliderOptions}>
                    <div className="item slider-one__slide-1">
                        <div className="slider-one__bg" style={{ backgroundImage: `url(${BGImg1})` }}></div>
                        <div className="slider-one__line"></div>
                        <div className="slider-one__shape-1"></div>
                        <div className="slider-one__shape-2"></div>
                        <div className="slider-one__shape-3"></div>
                        <div className="container">
                            <div className="slider-one__content ">
                                <div className="slider-one__floated lettering-text">technology</div>
                                <p className="slider-one__text">Unveil the best IT solutions right here</p>
                                <div className="slider-one__title-wrapper">
                                    <h2 className="slider-one__title">Service Provision</h2>
                                </div>
                                <div className="slider-one__btns">
                                    <Link to="/about" className="thm-btn slider-one__btn">
                                        <span>Learn More</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item slider-one__slide-2">
                        <div className="slider-one__bg" style={{ backgroundImage: `url(${BGImg2})` }}></div>
                        <div className="slider-one__line"></div>
                        <div className="slider-one__shape-1"></div>
                        <div className="slider-one__shape-2"></div>
                        <div className="slider-one__shape-3"></div>
                        <div className="container">
                            <div className="slider-one__content ">
                                <div className="slider-one__floated lettering-text">technology</div>
                                <p className="slider-one__text">Unveil the best IT solutions right here</p>
                                <div className="slider-one__title-wrapper">
                                    <h2 className="slider-one__title">Service Provision</h2>
                                </div>
                                <div className="slider-one__btns">
                                    <Link to="/about" className="thm-btn slider-one__btn">
                                        <span>Learn More</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item slider-one__slide-1">
                        <div className="slider-one__bg" style={{ backgroundImage: `url(${BGImg4})` }}></div>
                        <div className="slider-one__line"></div>
                        <div className="slider-one__shape-1"></div>
                        <div className="slider-one__shape-2"></div>
                        <div className="slider-one__shape-3"></div>
                        <div className="container">
                            <div className="slider-one__content ">
                                <div className="slider-one__floated lettering-text">technology</div>
                                <p className="slider-one__text">Unveil the best IT solutions right here</p>
                                <div className="slider-one__title-wrapper">
                                    <h2 className="slider-one__title">Service Provision</h2>
                                </div>
                                <div className="slider-one__btns">
                                    <Link to="/about" className="thm-btn slider-one__btn">
                                        <span>Learn More</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
            </div>

            <section className="section-padding--bottom section-padding--top about-five">
                <div className="container">
                    <div className="row gutter-y-60">
                        <div className="col-lg-6">
                            <div className="about-five__images">
                                <div className="about-five__images__shape"></div>
                                <img src={img1} className="wow fadeInUp" data-wow-duration="1500ms" alt="" />
                                <img src={img2} alt="" />
                                <div className="about-five__video pe-none">
                                    <Link to="#" onClick={() => setytShow(true)} className="video-popup">
                                        <i className="fa fa-play"></i>
                                    </Link>
                                </div>
                                <div className="about-five__images__caption count-box wow fadeInUp" data-wow-duration="1500ms">
                                    <span className="count-text">
                                        <AnimatedNumber value={22} duration={2500} formatValue={(v) => Math.round(v)} />
                                    </span>
                                    Years <br />
                                    Experience
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-five__content">
                                <div className="section-title ">
                                    <p className="section-title__text">About Company</p>
                                    <h2 className="section-title__title">An ambitious company focused on growth.</h2>

                                </div>
                                <div className="about-five__text">With our expertise in cutting-edge technologies and a passion for innovation,
                                    we empower you to bring your ideas to life. Our dedicated team of developers, and engineers work tirelessly
                                    to transform your vision into a tangible reality, delivering elegant and efficient solutions that exceed your
                                    expectations. Whether you're looking to develop a robust web platform, or create a game-changing software,
                                    we have the skills and knowledge to make it happen.
                                </div>
                                <div className="about-five__text">Let's collaborate and unlock the full potential of your vision today.</div>
                                <ul className="about-five__box">
                                    <li className="about-five__box__item gray-bg">
                                        <i className="about-five__box__icon icon-consulting"></i>
                                        <div className="about-five__box__content">
                                            <h3 className="about-five__box__title">
                                                <Link to="/contact">IT Consultant</Link>
                                            </h3>
                                            <p className="about-five__box__text">Smarter Solutions</p>
                                        </div>
                                    </li>
                                    <li className="about-five__box__item gray-bg">
                                        <i className="about-five__box__icon icon-chip"></i>
                                        <div className="about-five__box__content">
                                            <h3 className="about-five__box__title">
                                                <Link to="/about">IT Specialist</Link>
                                            </h3>
                                            <p className="about-five__box__text">Faster Solutions</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="about-four__meta">
                                    <div className="about-four__author">
                                        <img src={authorImg} alt="Avatar of Stepan Seliuk Founder CEO" />
                                        <div className="about-four__author__content">
                                            <h3 className="about-four__author__title">Stepan Seliuk</h3>
                                            <div className="about-four__author__designation">Founder CEO</div>
                                        </div>
                                    </div>
                                    <Link to="/service-01" className="thm-btn about-four__btn">
                                        <span>Learn More</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section style={{ backgroundImage: `url(${BGImg8})` }}
                className=" section-padding--top service-four gray-bg section-padding-xl--bottom section-has-bottom-margin background-repeat-no background-position-top-right">
                <div className="container">
                    <div className="section-title text-center">
                        <p className="section-title__text">Our Services</p>
                        <h2 className="section-title__title">We Provide Our Client Best <br />IT Solution Services</h2>
                    </div>
                    <OwlCarousel
                        className="thm-owl__carousel thm-owl__carousel--with-shadow service-four__carousel"
                        {...options}
                    >
                        <div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-new-product"></i>
                                </div>
                                <div className="service-card-three__content">
                                    <h3 className="service-card-three__title">
                                        <Link to="/infrastructure-plan">Product <br />Development</Link>
                                    </h3>
                                    <div className="service-card-three__text">Providing the solutions for non-IT businesses.</div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-protection"></i>
                                </div>
                                <div className="service-card-three__content">
                                    <h3 className="service-card-three__title">
                                        <Link to="/infrastructure-plan">Security <br />System</Link>
                                    </h3>
                                    <div className="service-card-three__text">Providing the solutions for non-IT businesses.</div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-web-development"></i>
                                </div>
                                <div className="service-card-three__content">
                                    <h3 className="service-card-three__title">
                                        <Link to="/infrastructure-plan">UI/UX <br />Designing</Link>
                                    </h3>
                                    <div className="service-card-three__text">Providing the solutions for non-IT businesses.</div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-new-product"></i>
                                </div>
                                <div className="service-card-three__content">
                                    <h3 className="service-card-three__title">
                                        <Link to="/infrastructure-plan">Digital <br />Marketing</Link>
                                    </h3>
                                    <div className="service-card-three__text">Providing the solutions for non-IT businesses.</div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="service-card-three">
                                <div className="service-card-three__icon">
                                    <i className="icon-analysis"></i>
                                </div>
                                <div className="service-card-three__content">
                                    <h3 className="service-card-three__title">
                                        <Link to="/infrastructure-plan">Data <br />Analysis</Link>
                                    </h3>
                                    <div className="service-card-three__text">Providing the solutions for non-IT businesses.</div>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </section>

            <section class="black-bg cta-two">
                <Parallax bgImage={BGImg} bgImageAlt="" className="section-padding-lg--top section-padding-lg--bottom" strength={1000}>
                    <div className="container">
                        <div className="cta-two__inner">
                            <h3 className="cta-two__title">Better IT Solutions And Services
                                At Your <span>Fingertips</span></h3>
                            <Link to="/contact" className="thm-btn cta-two__btn"><span>LEarn More</span></Link>
                        </div>
                    </div>
                </Parallax>
            </section>

            {/*<section*/}
            {/*    className="section-padding--bottom section-padding--top testimonials-two background-repeat-no background-position-top-center"*/}
            {/*    style={{ backgroundImage: `url(${BGImg5})` }}>*/}
            {/*    <div className="container">*/}
            {/*        <div className="row gutter-y-60">*/}
            {/*            <div className="col-lg-5">*/}
            {/*                <div className="testimonials-two__content">*/}
            {/*                    <div className="section-title ">*/}
            {/*                        <p className="section-title__text">Our clients</p>*/}
            {/*                        <h2 className="section-title__title">We Are Trusted*/}
            {/*                            Worldwide Peoples</h2>*/}
            {/*                    </div>*/}
            {/*                    <div className="testimonials-two__content__text">Sed ut perspiciatis unde omnis natus error sit*/}
            {/*                        voluptatem accusa ntium doloremque laudantium totam rem aperiamea queipsa quae abillo*/}
            {/*                        inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</div>*/}
            {/*                    <div className="testimonials-two__content__text">Pellentesque gravida lectus vitae nisi luctus,*/}
            {/*                        Finibus aliquet ligula ultrices.</div>*/}
            {/*                    <Link to="/about" className="thm-btn testimonials-two__content__btn"><span>View All*/}
            {/*                        feedbacks</span></Link>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-lg-7">*/}
            {/*                <div className="testimonials-two__items">*/}
            {/*                    <div className="row gutter-y-30">*/}
            {/*                        <div className="col-lg-12">*/}
            {/*                            <div className="testimonials-one-card">*/}
            {/*                                <div className="testimonials-one-card__image">*/}
            {/*                                    <img src="assets/images/resources/testi-1-1.jpg" alt="" />*/}
            {/*                                </div>*/}
            {/*                                <div className="testimonials-one-card__content">*/}
            {/*                                    <div className="testimonials-one-card__text">On the other hand denounc with*/}
            {/*                                        ghteo*/}
            {/*                                        indignation and dislike men who so beguiled and demoralized the charms*/}
            {/*                                        of*/}
            {/*                                        pleasure*/}
            {/*                                        the momen blinded by desire cannot foresee the pain and trouble.</div>*/}
            {/*                                    <h3 className="testimonials-one-card__title">Michal Rahul</h3>*/}
            {/*                                    <p className="testimonials-one-card__designation">Ul - UX Designer</p>*/}

            {/*                                    <i className="icon-right-quote testimonials-one-card__icon"></i>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="col-lg-12">*/}
            {/*                            <div className="testimonials-one-card">*/}
            {/*                                <div className="testimonials-one-card__image">*/}
            {/*                                    <img src="assets/images/resources/testi-1-2.jpg" alt="" />*/}
            {/*                                </div>*/}
            {/*                                <div className="testimonials-one-card__content">*/}
            {/*                                    <div className="testimonials-one-card__text">On the other hand denounc with*/}
            {/*                                        ghteo*/}
            {/*                                        indignation and dislike men who so beguiled and demoralized the charms*/}
            {/*                                        of*/}
            {/*                                        pleasure*/}
            {/*                                        the momen blinded by desire cannot foresee the pain and trouble.</div>*/}
            {/*                                    <h3 className="testimonials-one-card__title">Jessica Brown</h3>*/}
            {/*                                    <p className="testimonials-one-card__designation">Ul - UX Designer</p>*/}
            {/*                                    <i className="icon-right-quote testimonials-one-card__icon"></i>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}

            <div className="client-carousel client-carousel--two">
                <div className="container align-baseline">
                    <OwlCarousel className="thm-owl__carousel" {...logoOptions}>
                        <div className="item"><img src={gravity4} alt="gravity4" /></div>
                        <div className="item"><img src={davinci} alt="Davinci" /></div>
                        <div className="item"><img src={redLotus} alt="Red Lotus" /></div>
                        <div className="item"><img src={schoolLoop} alt="School Loop" /></div>
                        <div className="item"><img src={hCapcha} alt="hCapcha" /></div>
                        <div className="item"><img src={gartner} alt="Gartner" /></div>
                        <div className="item"><img src={cams} alt="Cams.com" /></div>
                        <div className="item"><img src={vonq} alt="VONQ" /></div>
                        <div className="item"><img src={crossover} alt="Crossover" /></div>
                        <div className="item"><img src={businessApps} alt="BusinessApps" /></div>
                    </OwlCarousel>
                </div>
            </div>

            <section className=" section-padding--top about-three about-three--home-one  section-padding--bottom">
                <div className="about-three__shape wow fadeInRight" data-wow-duration="1500ms"
                    style={{ backgroundImage: `url(${BGImg7})` }}>
                </div>
                <div className="container">
                    <div className="row gutter-y-60">
                        <div className="col-lg-6">
                            <div className="about-three__image">
                                <img src={whyChoose} className="wow fadeInUp"
                                    data-wow-duration="1500ms" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-three__content">
                                <div className="section-title ">
                                    <p className="section-title__text">Why You Should Choose Us</p>
                                    <h2 className="section-title__title">Company Benefits</h2>
                                </div>
                                <div className="about-three__text">We offer a suite of services across the IT spectrum.
                                    Our mission is to empower businesses to improve operations and achieve efficiency and growth.
                                </div>
                                <ul className="about-three__list">
                                    <li className="about-three__list__item">
                                        <div className="about-three__list__icon">
                                            <i className="icon-cloud"></i>
                                        </div>
                                        <div className="about-three__list__content">
                                            <h3 className="about-three__list__title">
                                                <Link to="cyber-security">Custom Software Development</Link>
                                            </h3>
                                            <p className="about-three__list__text">We provide end-to-end software development
                                                solutions that can transform your business operations.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="about-three__list__item">
                                        <div className="about-three__list__icon">
                                            <i className="icon-group"></i>
                                        </div>
                                        <div className="about-three__list__content">
                                            <h3 className="about-three__list__title">
                                                <Link to="/team">Expert Team Members</Link>
                                            </h3>
                                            <p className="about-three__list__text">Our IT support and managed services provide
                                                round-the-clock support, proactive monitoring, and maintenance of your IT
                                                infrastructure.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="cta-one">
                <div className="container">
                    <div className="cta-one__inner text-center wow fadeInUp" data-wow-duration="1500ms">
                        <div className="cta-one__circle"></div>
                        <div className="section-title ">
                            <p className="section-title__text">Need Any Technology Solution</p>
                            <h2 className="section-title__title section-title__title--light">Let's collaborate and work together on a project</h2>
                        </div>
                        <Link to="/contact" className="thm-btn thm-btn--light cta-one__btn">
                            <span>Start Now</span>
                        </Link>
                    </div>
                </div>
            </section>
            {ytShow &&
                <div className="YouTubePopUp-Wrap">
                    <div className="YouTubePopUp-Content">
                        <span className="YouTubePopUp-Close" onClick={() => setytShow(false)}></span>
                        <iframe src="https://www.youtube.com/embed/rzfmZC3kg3M?autoplay=1" title='ytvideo' allowFullScreen></iframe>
                    </div>
                </div>}
        </>
    )
}

export default Home