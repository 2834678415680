import React from 'react'
import { Link } from 'react-router-dom'
import IMG1 from '../../assets/images/resources/IMG1.jpg'
import CEO from '../../assets/images/team/CEO.jpg'
import COO from '../../assets/images/team/COO.jpg'
import Breadcumb from '../Breadcumb/Main'

const About = () => {
    const logoOptions = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        nav: true,
        navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
        dots: false,
        margin: 30,
        items: 2,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 30,
                items: 2
            },
            375: {
                margin: 30,
                items: 2
            },
            575: {
                margin: 30,
                items: 3
            },
            767: {
                margin: 50,
                items: 4
            },
            991: {
                margin: 40,
                items: 5
            },
            1199: {
                margin: 80,
                items: 5
            }
        }
    }
    return (
        <>
            <Breadcumb Title="About Us" Breadcumb="ABOUT" />
            <section className="about-four section-padding--top">
                <div className="container">
                    <div className="row gutter-y-60">
                        <div className="col-lg-6">
                            <div className="about-four__image">
                                <img src={IMG1} className="wow fadeInUp"
                                    data-wow-duration="1500ms" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-four__content">
                                <div className="section-title ">
                                    <p className="section-title__text">About Company</p>
                                    <h2 className="section-title__title">An ambitious company focused on growth.</h2>
                                </div>
                                <div className="about-four__text">With our expertise in cutting-edge technologies and a passion for innovation,
                                    we empower you to bring your ideas to life. Our dedicated team of developers, and engineers work tirelessly
                                    to transform your vision into a tangible reality, delivering elegant and efficient solutions that exceed your
                                    expectations. Whether you're looking to develop a robust web platform, or create a game-changing software,
                                    we have the skills and knowledge to make it happen.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-padding--bottom section-padding--top">
                <div className="container">
                    <div className="section-title text-center">
                        <p className="section-title__text">Our Team members</p>
                        <h2 className="section-title__title">Our experts provide <br /> IT Solutions Services</h2>

                    </div>
                    <div className="row gutter-y-30">
                        <div className="col-lg-3 col-md-6 col-sm-12"></div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                                <div className="team-card-one__image">
                                    <img src={CEO} alt="Founder Stepan Seliuk" />
                                    <div className="team-card-one__social">
                                        <ul className="team-card-one__social__links">
                                            <li>
                                                <a href="https://www.linkedin.com/in/stepanseliuk/" target="_blank">
                                                    <i className="fab fa-linkedin-in"></i>
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="team-card-one__social__icon">
                                            <i className="fa fa-share-alt"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-card-one__content">
                                    <h3 className="team-card-one__title">
                                        <Link to="#" className="pe-none">Stepan Seliuk</Link>
                                    </h3>
                                    <p className="team-card-one__designation">Founder CEO</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                                <div className="team-card-one__image">
                                    <img src={COO} alt="Co Founder Tatiana Seliuk" />
                                    <div className="team-card-one__social">
                                        <ul className="team-card-one__social__links">
                                            <li>
                                                <a href="https://www.linkedin.com/in/tatiana-seliuk/" target="_blank">
                                                    <i className="fab fa-linkedin-in"></i>
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="team-card-one__social__icon">
                                            <i className="fa fa-share-alt"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-card-one__content">
                                    <h3 className="team-card-one__title">
                                        <Link to="#" className="pe-none">Tatiana Seliuk</Link>
                                    </h3>
                                    <p className="team-card-one__designation">Co Founder COO</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About